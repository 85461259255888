<template>
  <div class="full-height hotel_bridge">
    <div class="box">
      <q-card class="hotel">
      	<div class="box_top text-center">
					<p><img src="/statics/img/bi_bk.png"></p>
					<p>최저가 예약페이지로 이동 중입니다.</p>
					<p><img src="/statics/img/loader_bridge.gif" class="loader"></p>
				</div>
        <span>
        	<p class="img"><img :src="getFileExist(hotel.main_img)" class="fitimg"></p>
        	<p>{{ getHotelName }}</p>
        	<p>{{ getHotelNameEng }}</p>
				</span>
        <!--<span><img :src="getFileExist(hotel.cate.logo_img)" class="company"></span>-->
      </q-card>
      <div class="footer">Powered by <img src="/statics/img/logo_hc.png"></div>
		</div>
  </div>
</template>

<script>
  import {QCard,QCardSection,QCardActions,QBtn} from 'quasar'
  import CommonComputed from 'src/_mixins/CommonComputed'
  import CommonMethods from 'src/_mixins/CommonMethods'
  export default {
    name: 'HotelBridge',
    components: {
      QCard,
      QCardSection,
      QCardActions,
      QBtn,
    },
    metaInfo () {
      return {
        "title": `${this.getAppName} : ${this.getHotelName}`,
        "titleTemplate": "%s",
        "meta": [
          {"vmid": "description", "name": "description", "content": this.getHotelName},
          {"vmid": "image", "name": "image", "content":  this.getHotelPlateImage},
          {"vmid": "og:title", "property": "og:title", "content": `${this.getHotelName} | ${this.getAppName}`},
          {"vmid": "og:description", "property": "og:description", "content": this.getHotelName},
          {"vmid": "og:image", "property": "og:image", "content": this.getHotelPlateImage}
        ]
      }
    },
    data () {
      return {
        evt: 'click',
        hotel: { name: '', name_eng: '', main_img: {url: ''}, cate: {logo_img: ''} },
        holdingTime: 2500,
      }
    },
    created () {
      this.getHotelData()
    },
    computed: {
      ...CommonComputed,
      getAccess () {
        return 'app'
      },
      getHotelName () {
        return this.hotel ? this.hotel.name : ''
      },
      getHotelNameEng () {
        return this.hotel ? this.hotel.name_eng : ''
      },
      getHotelPlateImage () {
        return this.getFileExist(this.hotel.main_img)
      }
    },
    methods: {
      ...CommonMethods,
      getHotelData () {
        const idx = this.$route.params.idx
        if (idx) {
          this.$axios.get('/hotels/fast/' + idx).then(rs => {
            this.hotel = rs.data
            this.startTimer()
          }).catch(() => {
          })
        }
      },
      setChartData () {
        let params = {
          hotel_idx: this.hotel.idx,
          hotel_gb: this.hotel.cate,
          access: this.getAccess,
          event: this.evt,
        }
        this.$axios.put('/charts/set_log_ip', params).then(rs => {
          if (rs.data.status === 'success') {
            this.$axios.put('/charts/set_data_day', params)
            this.$axios.put('/charts/set_data_week', params).then(rs2 => {
              // console.log(rs2.data.status === 'success', this.hotel.request_app)
              window.location.replace(this.getAccess === 'app' ? this.hotel.request_app : this.hotel[`request_${this.getAccess}`])
            })
          } else if (rs.data.status === 'exist') {
            // console.log(`exist : ${rs.data.cnt}`, this.hotel.request_app)
            window.location.replace(this.getAccess === 'app' ? this.hotel.request_app : this.hotel[`request_${this.getAccess}`])
          }
        })
      },
      startTimer () {
        setTimeout(function () {
          this.setChartData()
        }.bind(this), this.holdingTime)
      },
    },
  }
</script>

<style scoped>
	.contentArea {
		margin: 0 !important;
	}
	.hotel_bridge {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #f5f5f5;
		text-align: center;
	}
  .hotel_bridge .box {
		height: 100vh;
		text-align: center;
  }
	.hotel_bridge .box_top  {
    position: absolute;
		top: 0%;
		left: 50%;
		transform: translate(-50%, -95%);
		width: 100%;
  }
	.hotel_bridge .box_top p img {
    width: 240px;
  }
  .hotel_bridge .box_top p:nth-child(2) {
    font-size: 21px;
		font-weight: 300;
    padding: 30px 0;
		letter-spacing: -0.03em;
  }
  .hotel_bridge .q-card {
    padding: 50px;
		box-shadow: none;
		border-radius: 10px;
  }
  .hotel_bridge .hotel {
    position: absolute;
		top: 56%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 50%;
    max-width: 800px;
		display: flex;
		align-items: center;
		justify-content: center;
  }
  .hotel_bridge .hotel span {
    width: 100%;
    text-align: center;
    display: inline-block;
  }
  .hotel_bridge .hotel span:nth-child(3) {
    width: 18%;
    text-align: center;
    display: inline-block;
  }
  .hotel_bridge .hotel .img {
		position: relative;
    width: 200px;
		height: 160px;
		margin: 0 auto;
  }
  .hotel_bridge .hotel span p {
		letter-spacing: -0.02em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .hotel_bridge .hotel span p:nth-child(2) {
    font-size: 18px;
		font-weight: 500;
		line-height: 1.35em;
		margin-top: 20px;
  }
  .hotel_bridge .hotel span p:nth-child(3) {
    font-size: 15px;
		margin-top: 5px;
  }
  .hotel_bridge .hotel img.loader {
    width: 50px;
		opacity: 0.25;
    margin-bottom: 50px;
  }
  .hotel_bridge .hotel span .plate {
    width: auto;
    max-width: 110px;
    max-height: 110px;
  }
	.hotel_bridge .footer {
		position: absolute;
		bottom: 8vh;
		left: 50%;
		transform: translate(-50%, 0);
		font-size: 11px;
	}
	.hotel_bridge .footer img {
		height: 15px;
		margin-top: -2pt;
		margin-left: 2pt;
	}
</style>
