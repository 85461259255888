<script>
  import HotelBridge from 'src/pc/pages/HotelBridge'
  export default {
    extends: HotelBridge,
    components: {

    }
  }
</script>

<style scoped>
  .contentArea {
    margin: 0 !important;
  }
  .hotel_bridge {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f5f5f5;
    text-align: center;
  }
  .hotel_bridge .box {
    width: 100vw;
    height: 100vh;
    text-align: center;
  }
  .hotel_bridge .box img {
    
  }
  .hotel_bridge .box_top  {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -95%);
    width: 100%;
  }
  .hotel_bridge .box_top p img {
    width: 160px;
  }
  .hotel_bridge .box_top p:nth-child(2) {
    font-size: 17px;
    font-weight: 300;
    padding: 4vw 5% 4vw;
    letter-spacing: -0.03em;
  }
  .hotel_bridge .q-card {
    padding: 7vw 2vw;
    box-shadow: none;
    border-radius: 10px;
  }
  .hotel_bridge .hotel {
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hotel_bridge .hotel span {
    width: 100%;
    text-align: center;
    display: inline-block;
  }
  .hotel_bridge .hotel span:nth-child(3) {
    width: 18%;
    text-align: center;
    display: inline-block;
  }
  .hotel_bridge .hotel .img {
    position: relative;
    width: 120px;
    height: 100px;
    margin: 0 auto;
  }
  .hotel_bridge .hotel span p {
    letter-spacing: -0.02em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .hotel_bridge .hotel span p:nth-child(2) {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.35em;
    margin-top: 16px;
  }
  .hotel_bridge .hotel span p:nth-child(3) {
    font-size: 11px;
    margin-top: 5px;
  }
  .hotel_bridge .hotel img.loader {
    width: 50px;
    opacity: 0.25;
    margin-bottom: 10vw;
  }
  .hotel_bridge .hotel span .plate {
    width: auto;
    max-width: 110px;
    max-height: 110px;
  }
  .hotel_bridge .footer {
    position: absolute;
    bottom: 8vw;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 11px;
  }
  .hotel_bridge .footer img {
    height: 13px;
    margin-top: -1pt;
    margin-left: 2pt;
  }
</style>
